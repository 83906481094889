@import "../../../../themes/colors";
@import "../../../../themes/dimensions.scss";

.crash-provably-fair-modal {
    & > article {
        text-align: center;

        & > *:not(button) {
            text-align: left;
        }
    }

    .low {
        // color: $fail  !important;
    }

    .high {
        // color: $success  !important;
    }

    .bold {
        opacity: 0.3;
    }

    pre {
        background-color: $black !important;
        border: 2px solid $grey;
        border-radius: 4px;
        overflow-y: scroll;

        // &::-webkit-scrollbar-track {
        //     background: $grey;
        // }

        // &::-webkit-scrollbar-thumb {
        //     border-color: $grey;
        //     background: fade-out($color: black, $amount: 0.6);
        // }
    }

    table td {
        text-align: left !important;
        color: white;
        position: relative;
        height: 44px;

        & > span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            max-width: calc(100% - 20px);

            svg {
                width: 32px;
            }
        }

        &:first-child {
            width: 80px;
        }

        &:nth-child(2) {
            display: none;
        }

        // &:nth-child(4) {
        //     display: none;
        // }

        &:last-child {
            width: 100px;
        }

        @include mobile {
            &:nth-child(2) {
                width: 150px;
                display: table-cell;
            }
        }

        // @include tablet {
        //     &:nth-child(4) {
        //         width: 200px;
        //         display: table-cell;
        //     }
        // }

        button {
            padding: 0;
            margin: 0;
        }
    }
}

.crash-provably-fair-planet {
    display: none;
    position: absolute;
    top: 64px;
    right: -114px;

    @include large {
        display: block;
    }
}
