@import "../../themes/colors";

.button {
    align-items: center;
    background: none;
    background: transparent;
    border: none;
    border-radius: 12px;
    color: $white;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 0px;
    padding: 8px;
    text-align: center;

    transition: all 0.2s;
    transition: background 0.2s, color 0.2s, border 0.1s, box-shadow 0.1s;
    white-space: nowrap;

    &:not(.disabled):not(:active):not(.active):hover {
        color: $white;
        > svg {
            filter: drop-shadow(0px 0px 8px $neon-blue);
        }
    }

    &:not(.disabled):active,
    &:not(.disabled).active {
        color: $neon-blue;
    }

    &.transparent,
    &.transparent:hover,
    &.transparent:active,
    &.transparent.active {
        background-color: transparent;
        max-height: 24px;
        overflow: hidden;
    }

    &.loading {
        // ignore
    }

    &.normal {
        height: 48px;
        min-width: 56px;
        padding: 8px;
    }

    &.medium {
        height: 48px;
        min-width: 56px;
    }

    &.small {
        font-size: 12px;
        height: 24px;
        line-height: 14px;
        min-width: 24px;
    }

    &.blue {
        color: $white;
        background: transparent;
        border: 4px solid $neon-blue;

        &:not(.disabled):hover {
            border-color: $neon-blue;
            background: $neon-blue-hover 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 8px $neon-blue, inset 0px 0px 12px $neon-blue;
        }

        &:not(.disabled):active,
        &:not(.disabled).active {
            border-color: $neon-blue;
            background: $neon-blue-hover 0% 0% no-repeat padding-box;
            color: $neon-blue;
        }

        &:not(.disabled):active {
            box-shadow: 0px 0px 0px transparent, inset 0px 0px 0px transparent;
        }
    }

    &.green {
        color: $white;
        background: transparent;
        border: 4px solid $neon-green;

        &:not(.disabled):hover {
            border-color: $neon-green;
            background: $neon-green-hover 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 8px $neon-green, inset 0px 0px 12px $neon-green;
        }

        &:not(.disabled):active,
        &:not(.disabled).active {
            border-color: $neon-green;
            background: $neon-green-hover 0% 0% no-repeat padding-box;
            color: $neon-green;
        }

        &:not(.disabled):active {
            box-shadow: 0px 0px 0px transparent, inset 0px 0px 0px transparent;
        }
    }

    &.disabled {
        border-color: $grey !important;
        color: $grey !important;
        box-shadow: none;
        cursor: default;
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
