@import "../../themes/colors.scss";
@import "../../themes/dimensions.scss";

.crash-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: fade-out($color: black, $amount: 0.1);
    z-index: 11;
    margin: 0;
}

.crash-modal {
    position: absolute;
    z-index: 12;
    transition: all 0.2s;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    height: 816px;

    max-width: 100vw;
    max-height: 100vh;

    min-width: 100vw;
    min-height: 100vh;
    box-shadow: inset -8px -8px 104px rgba($color: $black, $alpha: 50);

    background: $panel-background 0% 0% no-repeat padding-box;
    padding: 32px 32px;

    @include tablet {
        max-width: 75vw;
        max-height: 75vh;
        min-height: auto;
        min-width: auto;

        border: 4px solid $neon-blue;
        border-radius: 32px;
        box-shadow: 0px 0px 8px $neon-blue, inset 0px 0px 8px $neon-blue,
            inset -8px -8px 104px rgba($color: $black, $alpha: 50);

        border-radius: 48px;
        padding: 48px 48px;
    }

    @include large {
        border-radius: 64px;
        padding: 80px 80px;
    }

    display: flex;
    flex-direction: column;

    &-small {
        width: 504px;
        height: 696px;
    }

    &-scrollable > article {
        overflow: auto;
        overflow-y: auto;
        overflow-x: visible;
    }

    .crash-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        padding: 0;

        @include tablet {
            margin-bottom: 24px;
        }

        @include large {
            margin-bottom: 32px;
        }

        h2 {
            color: $neon-blue;
            text-align: left;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
        }
    }

    .crash-modal-footer {
        margin-top: 16px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        & > button {
            min-width: 168px;
            padding: 16px 32px;
        }
    }

    article {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600px;
        color: $white;
        text-align: left;
        flex: 1;
    }
}
