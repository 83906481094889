@import "../../themes/colors.scss";
@import "../../themes/dimensions.scss";

@keyframes attention {
    0% {
        border-color: $white;
        background: transparent;
        box-shadow: none;
    }

    50% {
        border-color: $neon-green;
        background: $neon-green-hover 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px $neon-green, inset 0px 0px 12px $neon-green;
    }

    100% {
        border-color: $white;
        background: transparent;
        box-shadow: none;
    }
}

.crashBetPlacement {
    background-color: rgba($color: $panel-background, $alpha: 0.5);
    position: relative;
    align-items: center;
    border: 4px solid $neon-blue;
    box-shadow: 0px 0px 8px $neon-blue, inset 0px 0px 8px $neon-blue;
    border-radius: 16px;
    padding: 24px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 0;

        & > section {
            margin-top: 8px;
        }
    }

    @include medium {
        padding: 24px 32px !important;
    }

    @include large {
        padding: 32px !important;
    }

    &.highlight {
        border-color: $neon-green;
        box-shadow: 0px 0px 8px $neon-green, inset $neon-green;
    }

    & > section {
        display: flex;
        width: 100%;

        @include tablet {
            width: auto;
        }

        &:first-child {
            margin-top: 0;
        }

        & > * {
            margin-left: 16px;

            &:first-child {
                margin-left: 0;
            }
        }

        &.amount {
            display: flex;

            & > *:first-child {
                flex: 1;
                min-width: 132px;

                @include tablet {
                    min-width: 168px;
                    max-width: 168px;
                }
            }
        }

        &.multiply {
            display: none;

            @include large {
                display: flex;
            }
        }

        &.add {
            display: none;

            @include medium {
                display: flex;
            }
        }

        &.minmax {
            display: none;

            @include medium {
                display: flex;
            }
        }

        &.multiplier {
            & > *:first-child {
                flex: 1;
                min-width: 132px;

                @include tablet {
                    min-width: 168px;
                    max-width: 168px;
                }
            }

            @include tablet {
                margin-left: 16px;
            }
        }

        &.submit {
            grid-column: 1/3;
            flex-grow: 1;

            & > button {
                width: 100%;
                padding: 16px 32px;

                &.attention {
                    animation-name: attention;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in-out;
                }
            }
        }

        @include mobile {
            margin-top: 0 !important;
            margin-left: 16px;

            &:first-child {
                margin-left: 0;
            }

            &.submit > button {
                width: auto;
                margin-left: auto;
            }
        }
    }

    @include mobile {
        & {
            flex-direction: row;
        }
    }
}
