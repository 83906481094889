.hash-string {
    display: inline-flex;
    flex-direction: row;
    max-width: 100%;
    white-space: nowrap;
    align-items: center;
    position: relative;

    & > span {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
        opacity: 1;
        transition: opacity 0.2s;
        font-family: "Fira Code" !important;
    }

    & > button > svg {
        width: 16px;
        height: 16px;
    }

    &::after {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        content: "Copied!";
        opacity: 0;
        transition: opacity 0.2s;
    }

    &.copied {
        & > span {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }
}
