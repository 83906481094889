@import "../../themes/colors.scss";

.table {
    margin-top: 30px;
    position: relative;

    & > span {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 10px;
        z-index: 1;

        & > i {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -9px;
            margin-left: -9px;
            font-size: 18px;
            width: 18px;
            height: 18px;
        }
    }

    & > table {
        width: 100%;
        background: rgba($color: #000000, $alpha: 0.3);
        border-radius: 10px;
        overflow: hidden;

        > thead > tr > td {
            text-align: center;
            font-size: 12px;
            line-height: 15px;
            vertical-align: middle;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            padding: 10px;
            color: $grey;

            & > button {
                padding: 0 !important;
                height: 1rem;

                i {
                    font-size: 10px;
                }
            }
        }

        > tbody {
            > tr {
                height: 44px;

                &:nth-child(odd) {
                    //background: rgba($color: $black, $alpha: 50);
                }

                > td {
                    text-align: center;
                    font-size: 12px;
                    line-height: 15px;
                    vertical-align: middle;
                    transition: all 0.2s;
                    padding: 6px 10px;
                    opacity: 0.6;
                }

                &.clickable > td {
                    cursor: pointer;
                }

                &.active > td {
                    opacity: 1;
                }

                @media (hover: hover) and (pointer: fine) {
                    &:hover > td {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.checkbox-single,
    &.checkbox-multi {
        & > table td {
            &:first-child {
                width: 60px;
            }

            &:last-child {
                padding-right: 40px;
            }
        }
    }
}
