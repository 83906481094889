@import "../../../../themes/colors";
@import "../../../../themes/dimensions.scss";


.crash-faq-modal {
    position: relative;
    overflow: auto;

    article {
        &>p:fir {
            margin-bottom: 30px !important;
        }

        // items
        &>div {
            margin-top: 30px;

            &>div {
                margin-bottom: 10px;

                h5 {
                    display: flex;
                    flex-direction: row;
                    cursor: pointer;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 10px;
                    opacity: 1;
                    padding: 0 20px;
                    color: $white;
                    text-align: left;
                    font: normal normal 600 12px/15px Montserrat;
                    letter-spacing: 0px;
                    opacity: 1;
                    transition: all .3s;

                    background: $grey 0% 0% no-repeat padding-box;

                    span>svg {
                        width: 14px;
                        height: 14px;
                        color: $white;
                    }
                }

                article {
                    overflow: hidden;
                    max-height: 0px;
                    transition: all .3s;
                }

                p {
                    color: $white;
                    text-align: left;
                    font: normal normal 600 12px/20px Montserrat;
                    letter-spacing: 0px;
                    opacity: 1;
                    padding: 20px;

                }

                &:hover,
                &.expanded {
                    h5 {
                        background: $neon-blue 0% 0% no-repeat padding-box;
                        color: $panel-background;
                    }
                }

                &.expanded {
                    h5 {
                        color: $panel-background;

                    }

                    article {
                        max-height: 300px;
                    }
                }
            }
        }
    }
}

.faq-planet {
    display: none;

    @include large {
        display: block;
    }

    position: absolute;
    bottom: -40px;
    left: -65px;
}