@import "../../../../themes/colors.scss";
@import "../../../../themes/dimensions.scss";

.crash-information-modal {
    & > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        & > label {
            display: block;
            color: $grey;
            text-align: left;
            font: normal normal 600 14px/18px Montserrat;
        }

        & > span {
            color: $white;
            text-align: left;
            font: normal normal 600 14px/18px Montserrat;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-top: 5px;

            span {
                max-width: calc(100% + -40px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            svg {
                width: 20px;
            }

            button {
                padding: 0 !important;
                display: flex;
                align-items: center;
            }
        }
    }
}

.crash-information-planet {
    display: none;
    position: absolute;
    top: 64px;
    right: -114px;

    @include large {
        display: block;
    }
}
