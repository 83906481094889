@import "../../../../themes/colors";
@import "../../../../themes/dimensions.scss";

.crash-how-to-play-modal {
    position: relative;
    overflow: auto;

    p {
        margin-bottom: 20px;
    }
}

.crash-how-to-play-planet {
    position: absolute;
    display: none;
    top: 100px;
    left: -108px;

    @include large {
        display: block;
    }
}
