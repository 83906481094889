@import "../../themes/colors";
@import "../../themes/dimensions.scss";

@keyframes shake {
    2% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    4% {
        transform: translate(0px, 1px) rotate(-0.7deg);
    }

    6% {
        transform: translate(0px, 1px) rotate(0.7deg);
    }

    8% {
        transform: translate(1px, 0px) rotate(-0.7deg);
    }

    10% {
        transform: translate(0px, 0px) rotate(0.7deg);
    }

    12% {
        transform: translate(1px, 0px) rotate(-0.7deg);
    }

    14% {
        transform: translate(1px, 0px) rotate(0.7deg);
    }

    16% {
        transform: translate(0px, 1px) rotate(0.7deg);
    }

    18% {
        transform: translate(0px, 1px) rotate(-0.7deg);
    }

    20% {
        transform: translate(0px, 1px) rotate(0.7deg);
    }

    22% {
        transform: translate(0px, 1px) rotate(0.7deg);
    }

    24% {
        transform: translate(0px, 0px) rotate(0.7deg);
    }

    26% {
        transform: translate(1px, 1px) rotate(-0.7deg);
    }

    28% {
        transform: translate(1px, 1px) rotate(-0.7deg);
    }

    30% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    32% {
        transform: translate(1px, 0px) rotate(-0.7deg);
    }

    34% {
        transform: translate(0px, 1px) rotate(0.7deg);
    }

    36% {
        transform: translate(0px, 1px) rotate(-0.7deg);
    }

    38% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    40% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    42% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    44% {
        transform: translate(1px, 1px) rotate(-0.7deg);
    }

    46% {
        transform: translate(1px, 0px) rotate(-0.7deg);
    }

    48% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    50% {
        transform: translate(1px, 1px) rotate(-0.7deg);
    }

    52% {
        transform: translate(1px, 0px) rotate(0.7deg);
    }

    54% {
        transform: translate(0px, 1px) rotate(0.7deg);
    }

    56% {
        transform: translate(0px, 0px) rotate(-0.7deg);
    }

    58% {
        transform: translate(0px, 0px) rotate(0.7deg);
    }

    60% {
        transform: translate(1px, 1px) rotate(-0.7deg);
    }

    62% {
        transform: translate(0px, 0px) rotate(0.7deg);
    }

    64% {
        transform: translate(1px, 0px) rotate(-0.7deg);
    }

    66% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    68% {
        transform: translate(1px, 0px) rotate(-0.7deg);
    }

    70% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    72% {
        transform: translate(0px, 0px) rotate(-0.7deg);
    }

    74% {
        transform: translate(1px, 0px) rotate(-0.7deg);
    }

    76% {
        transform: translate(0px, 1px) rotate(-0.7deg);
    }

    78% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    80% {
        transform: translate(0px, 0px) rotate(-0.7deg);
    }

    82% {
        transform: translate(1px, 0px) rotate(-0.7deg);
    }

    84% {
        transform: translate(0px, 0px) rotate(-0.7deg);
    }

    86% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    88% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    90% {
        transform: translate(1px, 1px) rotate(0.7deg);
    }

    92% {
        transform: translate(0px, 0px) rotate(-0.7deg);
    }

    94% {
        transform: translate(1px, 1px) rotate(-0.7deg);
    }

    96% {
        transform: translate(0px, 1px) rotate(0.7deg);
    }

    98% {
        transform: translate(0px, 0px) rotate(-0.7deg);
    }

    0%,
    100% {
        transform: translate(0, 0) rotate(0);
    }
}

.crashRenderer {
    overflow: initial !important;
    width: min-content;
    min-height: 440px;
    margin: 0 auto;
    border-radius: 40px 40px 16px 16px;
    --plot-offset: 50px;
    --plot-width: calc(100% - 150px);
    --line-color: #{$neon-blue};

    @include large {
        border-radius: 16px 64px 16px 16px;
        min-height: 560px;
    }

    & > main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        border-radius: inherit;
        overflow: hidden;
        pointer-events: none;
    }

    &.crashed {
        main {
            animation-name: shake;
            animation-duration: 150ms;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 2;
        }
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        overflow: hidden;
        pointer-events: none;
        border-radius: inherit;
        content: "";
        border: 4px solid var(--line-color, #{$neon-blue});
        box-shadow: 0px 0px 8px var(--line-color, #{$neon-blue}), inset 0px 0px 8px var(--line-color, #{$neon-blue});
    }

    &.finished {
        // border-color: $neon-red;
        // box-shadow: 0px 0px 8px $neon-red, inset 0px 0px 8px $neon-red;
    }

    & > h2 {
        position: absolute;
        top: calc(10% + 180px);
        left: calc(var(--plot-offset) + var(--plot-width) * -0.2);
        width: var(--plot-width);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 12px;
        z-index: 2;

        & > * {
            margin: 0 5px;
        }

        & > i {
            margin-right: 0;
        }

        &.won > span {
            color: $neon-green;
        }
    }

    &.scale75 {
        min-height: 450px;
        --plot-offset: calc(50px * 0.75);
        --plot-width: calc(100% - 180px * 0.75);

        & > h2 {
            transform: scale(0.75);
            top: calc(9% + 113px);
        }
    }

    &.scale50 {
        min-height: 300px;
        --plot-offset: calc(50px * 0.5);
        --plot-width: calc(100% - 180px * 0.5);

        & > h2 {
            transform: scale(0.5);
            top: calc(8% + 75px);
        }
    }

    .wallet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        position: absolute;
        z-index: 6;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, 50%);
        margin-bottom: -4px;
        height: 40px;
        min-width: 240px;
        padding: 0 8px;
        border-radius: 32px;

        background: $panel-background 0% 0% no-repeat padding-box;
        border: 4px solid $neon-blue;
        box-shadow: 0px 0px 8px $neon-blue, inset 0px 0px 8px $neon-blue;
        text-align: center;
        transition: none !important;

        box-sizing: border-box;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0px;

        color: $neon-blue;
        text-shadow: 0px 0px 8px $neon-blue;

        @include tablet {
            font-size: 20px;
            line-height: 24px;

            left: 64px;
            top: 0;
            height: 64px;
            margin-bottom: 0;
            transform: translate(0, -50%);
            min-width: 272px;
            padding: 0 16px;
        }

        @include large {
            left: 0;
            margin-left: -208px;
            transform: translate(-50%, -50%);
        }
    }

    & > img {
        position: absolute;
        z-index: 5;
        top: 4px;
        right: 50%;
        object-fit: contain;
        transform: translate(50%, -50%);
        max-height: 104px;
        display: block;

        @include tablet {
            max-height: 140px;
            top: 0px;
            right: 40px;
            transform: translate(0, -50%);
        }

        @include medium {
            max-height: 184px;
        }

        @include large {
            right: 50%;
            transform: translate(50%, -50%);
        }
    }

    & + section {
        padding-top: 32px;

        @include tablet {
            padding-top: 24px;
        }
    }
}
