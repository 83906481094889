@import "../../themes/colors.scss";
@import "../../themes/dimensions.scss";

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.crash-splash {
    background-color: $panel-background;
    background-image: url("./assets/crash-background.jpg");
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;

    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    cursor: pointer;

    &::before {
        content: "";
        background: black;
        opacity: 0.3;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    & > i {
        background: url("./assets/crash-logo.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 400px;
        height: 225px;
        max-height: 100%;
        max-width: 100%;

        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: -20px;

        @include tablet {
            width: 640px;
            height: 360px;
            margin-top: -40px;
        }

        @include large {
            width: 960px;
            height: 540px;
            margin-top: -80px;
        }
    }

    & > span {
        color: $white;
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        text-transform: uppercase;

        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 120px;
        white-space: nowrap;

        animation-name: blink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;

        @include tablet {
            margin-top: 200px;
        }

        @include large {
            margin-top: 332px;
        }
    }

    & > footer {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        display: block;
        padding: 24px 0;

        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        color: $white;

        @include tablet {
            font-size: 16px;
            line-height: 22px;
        }
    }
}
