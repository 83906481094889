@import "../../../../themes/colors.scss";
@import "../../../../themes/dimensions.scss";

.crash-settings-modal {
    & > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }
}

.crash-settings-planet {
    display: none;
    position: absolute;
    top: 64px;
    right: -114px;

    @include large {
        display: block;
    }
}
