@import "../../themes/colors.scss";
@import "../../themes/dimensions.scss";

.crashGamePage {
    background-color: $panel-background;
    background-image: url("../crash-splash/assets/crash-background.jpg");
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;

    display: flex;
    flex-direction: column;
    position: relative;

    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 16px;

    @include tablet {
        padding: 48px;
    }

    @include large {
        padding: 48px 16px;
    }

    &::before {
        content: "";
        background: black;
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    & > * {
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
            width: 100%;
        }
    }

    & > div {
        display: grid;
        grid-auto-flow: row;
        gap: 8px;
        max-width: 1245px;
        position: relative;
        padding-top: 32px;

        @include tablet {
            gap: 16px;
        }

        & > div {
            overflow: hidden;
            position: relative;
            width: 100%;
            grid-row: 3;

            &.crashRenderer {
                width: 100%;
                grid-row: 1;
            }

            &:not(.crashRenderer) {
                // min-width: 400px;
                min-height: 328px;

                @include large {
                    min-height: 400px;
                }
            }
        }

        & > section.crashBetPlacement {
            grid-row: 2;
        }

        & > section.crashHistory {
            grid-row: 4;

            @include large {
                grid-row: 3;
            }
        }

        & > footer {
            grid-row: 5;

            @include large {
                grid-row: 4;
            }
        }

        @include large {
            grid-template-columns: minmax(400px, 30%) minmax(0, 70%);

            & > div {
                grid-row: 1;
            }

            & > section {
                grid-column: 1 / span 2;
            }

            & > footer {
                grid-column: 1 / span 2;
            }
        }

        @media only screen and (min-width: 1600px) {
            grid-template-columns: minmax(0px, 400px) minmax(0px, 1fr);
        }
    }

    &.fullscreen {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        margin: 0;
        background: $black;
        transition: none;

        & > * {
            display: none;
        }

        & > div,
        & > section {
            display: grid;
        }

        & > div {
            height: 100%;
            grid-template-rows: 1fr min-content;

            & > div.crashRenderer {
                min-height: auto;
            }

            & > div:not(.crashRenderer) {
                display: none;

                @include large {
                    display: block;
                }
            }
        }
    }

    & > div {
        & > div,
        & > section.crashRenderer {
            transition: all 1s ease;
            transition-delay: 0.2s !important;
        }

        & > div {
            transform: translate(0, 0);
            z-index: 5;

            .wallet {
                transition: all 1s ease 1s;
                transition-delay: 0.2s !important;
            }
        }

        & > section.crashBetPlacement {
            transition: all 1s ease 0s;
            transition-delay: 0.2s !important;
            transform: translateY(0);

            // @include large {
            //     transition-delay: 1s;
            // }
        }

        & > footer,
        & > section.crashHistory {
            transition: all 1s ease 1s;
            transition-delay: 0.2s !important;
            transform: translateY(0);
        }
    }

    .green-planet,
    .orange-planet,
    .purple-planet {
        position: absolute;
        z-index: 10;

        &.green-planet {
            left: -68px;
            top: -20px;
        }

        &.orange-planet {
            left: -104px;
            bottom: 201px;
        }

        &.purple-planet {
            right: -54px;
            top: 28px;
        }
    }

    &.noPlanet {
        .purple-planet {
            transform: translateX(120vw);
        }

        .green-planet,
        .orange-planet {
            transform: translateX(-120vw);
        }
    }
}
