.code {
    &>pre {
        overflow: auto;
        max-height: 300px;
        margin: 20px 0;

        * {
            font-family: "Fira Code" !important;
        }
    }
}