@import "../../themes/colors.scss";

.textfield {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    transition: all 0.2s;

    // prefix
    & > span:first-child {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        left: 16px;
        color: $white;
        font-size: 14px;
        line-height: 16px;
    }

    // clearable
    & > button:last-child {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        padding: 0;
        min-width: auto;
        align-items: center;
        right: 16px;
        background: transparent;
        height: auto;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    & > input {
        background: $black;
        border: 2px solid $white;
        border-radius: 12px;
        box-sizing: border-box;
        color: $white;
        font-size: 14px;
        line-height: 16px;
        height: 48px;
        outline: none !important;
        padding: 0 16px;
        transition: all 0.2s;
        width: 100%;

        &.prefix {
            padding-left: 30px;
        }

        &.clearable {
            padding-right: 40px;
        }

        &::placeholder {
            color: $white;
            transition: all 0.2s;
        }

        &:active:not(.disabled)::placeholder,
        &:focus:not(.disabled)::placeholder {
            color: transparent;
        }
    }

    &:hover > input {
        &::placeholder {
            color: $white;
        }
    }

    &:hover > input:not(.disabled),
    &:focus-within > input:not(.disabled) {
        border-color: $neon-blue;
        box-shadow: 0px 0px 8px $neon-blue, inset 0px 0px 8px $neon-blue;
    }

    &.disabled:not(.readOnly) {
        cursor: default;
        pointer-events: none;
        border-color: $grey !important;
        color: $grey !important;
        box-shadow: none !important;
    }

    &.readOnly > input {
        background: $input-background;
        color: $input-foreground;
        box-shadow: none !important;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 15px;
        text-align: left;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
}
