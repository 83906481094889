@import "../../themes/colors";
@import "../../themes/dimensions.scss";

@keyframes jump {
    0% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
    40% {
        transform: translate3d(0, 5px, 0) scale3d(1, 1, 1);
    }
    100% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.planetItem {
    transition: transform 1s ease;
    transform-origin: center center;
    display: none;

    @include large {
        display: block;
    }

    & > span {
        position: relative;
        display: block;
        transition: all 0.2s 0.1s;
        width: 100%;
        height: 100%;

        &::after {
            content: "";
            position: absolute;
            width: 80%;
            height: 80%;
            left: 5%;
            top: 5%;
            border-radius: 50%;
            box-shadow: 0px 0px 0px transparent;
            transition: all 0.2s;
        }

        & > svg,
        & > img {
            transition: left 0.2s, top 0.2s, bottom 0.2s;
            width: 80px;
            height: 80px;
        }

        & > img {
            object-fit: contain;
        }
    }

    &.jumping {
        & > span {
            animation: jump 0.8s ease-in-out infinite;
        }
    }

    &.shrink > span {
        animation: none !important;
        transform: scale(0) !important;
        transition-delay: 0;
    }

    &.shrink::before {
        opacity: 0;
        transition-delay: 0;
    }

    &.active {
        transform: scale(2);
    }

    &.animate {
        & > span > svg,
        & > span > img {
            animation: rotating 20s linear infinite;
        }

        &.green {
            & > span {
                animation-delay: 0.2s;
            }
        }

        &.orange {
            & > span {
                animation-delay: 0.6s;
            }
        }

        &.purple {
            & > span {
                animation-delay: 0.9s;
            }
        }
    }

    &.green {
        & > span::after {
            box-shadow: 0px 0px 64px 8px $planet-green-shadow;
        }

        &.clickable:hover {
            & > span::after {
                box-shadow: 0px 0px 80px 8px $planet-green-shadow;
            }
        }
    }

    &.orange {
        & > span::after {
            box-shadow: 0px 0px 64px 8px $planet-orange-shadow;
        }

        &.clickable:hover {
            & > span::after {
                box-shadow: 0px 0px 80px 8px $planet-orange-shadow;
            }
        }
    }

    &.purple {
        & > span::after {
            box-shadow: 0px 0px 64px 8px $planet-purple-shadow;
        }

        &.clickable:hover {
            & > span::after {
                box-shadow: 0px 0px 80px 8px $planet-purple-shadow;
            }
        }
    }

    &:not(.empty) {
        &::before {
            transition: all 0.2s 0.2s;
            content: "";
            color: $white;
            text-align: center;
            position: absolute;
        }
    }

    &.question-mark {
        & > span > svg,
        & > span > img {
            width: 160px;
            height: 160px;
        }

        &::before {
            content: "?";
            transform: rotate(-12deg);
            font-size: 96px;
            line-height: 120px;
            font-weight: 700;
            font-style: normal;
            top: -117px;
            left: 1px;
        }
    }

    &.exclamation {
        & > span > svg,
        & > span > img {
            width: 160px;
            height: 160px;
        }

        &::before {
            content: "!";
            transform: rotate(-12deg);
            font-size: 96px;
            line-height: 120px;
            font-weight: 700;
            top: -116px;
            left: 21px;
        }
    }

    &.hash {
        & > span > svg,
        & > span > img {
            width: 160px;
            height: 160px;
        }

        &::before {
            content: "#";
            transform: rotate(11.29deg);
            font-size: 96px;
            line-height: 120px;
            font-weight: 700;
            top: -109px;
            right: 5px;
            color: var(--white);
            text-align: center;
        }
    }
}
