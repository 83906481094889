@import "../../themes/colors";
@import "../../themes/dimensions.scss";

@keyframes won {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.15);
    }
}

.crashParticipants {
    background-color: rgba($color: $panel-background, $alpha: 0.5);
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    box-sizing: border-box;
    overflow: initial !important;
    border: 4px solid $neon-blue;
    box-shadow: 0px 0px 8px $neon-blue, inset 0px 0px 8px $neon-blue;
    border-radius: 16px;
    padding: 32px 24px;

    @include medium {
        padding: 32px;
        min-width: 400px;
    }

    @include large {
        border-radius: 64px 16px 16px 16px;
        padding: 56px 32px 32px;
    }

    &.won {
        border-color: $neon-green;
        box-shadow: 0px 0px 8px $neon-green, inset 0px 0px 8px $neon-green;
    }

    &.loss {
        border-color: $neon-red;
        box-shadow: 0px 0px 8px $neon-red, inset 0px 0px 8px $neon-red;
    }

    & > header {
        display: flex;
        margin-bottom: 16px;

        font-size: 16px;
        font-weight: 16px;
        font-weight: 600;
        align-items: center;

        & > h4 {
            flex-grow: 1;
            margin: 0;
            color: $neon-blue;
        }

        & > span {
            text-align: right;
            color: $neon-blue;
        }

        & > button {
            padding: 0;
            margin-right: 8px;
        }
    }

    & > main {
        flex-grow: 1;
        height: 100%;
        position: relative;

        & > div {
            bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 8px;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;

            mask-image: -webkit-gradient(
                linear,
                left 80%,
                left bottom,
                from(rgba(42, 46, 69, 1)),
                to(rgba(42, 46, 69, 0))
            );
            -webkit-mask-image: -webkit-gradient(
                linear,
                left 80%,
                left bottom,
                from(rgba(42, 46, 69, 1)),
                to(rgba(42, 46, 69, 0))
            );

            & > div {
                display: flex;
                gap: 8px;
                width: 100%;
                align-items: center;

                font-size: 14px;
                font-weight: 16px;
                font-weight: 600;

                & > div {
                    flex-grow: 1;
                    flex-shrink: 1;
                    color: $white;
                    text-align: left;
                }

                // profile picture
                & > img {
                    width: 24px;
                    height: 24px;
                    border: 1px solid $grey;
                    border-radius: 12px;
                }

                & > span {
                    min-width: 64px;
                    transition: all 0.2s;

                    color: $white;
                    text-align: center;

                    &:last-child {
                        text-align: right;
                        color: $grey;
                    }
                }

                &.won > span:last-child {
                    color: $neon-green;
                }

                &.lost > span:last-child {
                    color: $neon-red;
                }
            }

            // &::after {
            //     background: linear-gradient(180deg, transparent, rgba($color: $panel-background, $alpha: 0.5));
            //     bottom: 0;
            //     content: "";
            //     height: 150px;
            //     left: 0;
            //     pointer-events: none;
            //     position: absolute;
            //     right: 0;
            // }
        }
    }
}
