$neon-red: #ff0400;
$neon-orange: #ff8002;
$neon-yellow: #ffea00;
$neon-green: #3dff00;
$neon-blue: #04fbff;

$neon-green-hover: rgba(61, 255, 0, 0.1);
$neon-blue-hover: rgba(4, 251, 255, 0.1);

$panel-background: #000f2f;
$black: #000000;
$grey: #909090;
$white: #ffffff;

$planet-green-shadow: #62df6699;
$planet-orange-shadow: #ea6e2d;
$planet-purple-shadow: #8520e2;

$input-background: #040303;
$input-foreground: #2a2e45;
