@import "../../themes/colors";
@import "../../themes/dimensions.scss";

footer {
    margin: 24px 0;
    color: $grey;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    // @media only screen and (min-width: 499px) {
    //     margin: 0 32px;
    // }

    @include large {
        flex-direction: row;
        margin: 0;
        padding: 0 32px;
    }

    @include tablet {
        font-size: 14px;
        line-height: 16px;
    }

    > span {
        flex: 1;
        margin-top: 24px;
        text-align: center;

        @include large {
            margin-top: 0;
            text-align: left;
        }
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        display: inline-block;

        &:not(:last-child) {
            border-right: 1px solid $grey;
            padding-right: 8px;
            margin-right: 8px;

            @include tablet {
                padding-right: 16px;
                margin-right: 16px;
            }
        }

        & > button {
            color: $grey;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;

            @include tablet {
                font-size: 14px;
                line-height: 16px;
            }

            text-decoration: none;
            cursor: pointer;
            background: transparent;
            border: none;
            padding: 0;
            margin: 0;

            transition: color 0.2s;

            &:hover {
                color: $white;
            }
        }
    }
}
