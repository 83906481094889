@mixin xlarge {
    @media only screen and (min-width: 1601px) {
        @content;
    }
}

@mixin large {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin medium {
    @media only screen and (min-width: 960px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 720px) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (min-width: 576px) {
        @content;
    }
}

@mixin landscape {
    @media only screen and (max-width: 575px) and (orientation: landscape) {
        @content;
    }
}

@mixin application {
    @media only screen and (display-mode: standalone) {
        @content;
    }
}
