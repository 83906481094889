@import "../../themes/colors";
@import "../../themes/dimensions.scss";

.crashHistory {
    background-color: rgba($color: $panel-background, $alpha: 0.5);
    padding: 20px 32px;
    position: relative;
    height: 64px;
    overflow: hidden;

    border: 4px solid $neon-blue;
    box-shadow: 0px 0px 8px $neon-blue, inset 0px 0px 8px $neon-blue;
    border-radius: 16px 16px 40px 40px;

    @include large {
        border-radius: 16px 16px 64px 64px;
    }

    & > div {
        display: flex;
        overflow: hidden;
        position: relative;
        max-width: 1245px;

        mask-image: -webkit-gradient(
            linear,
            80% bottom,
            right bottom,
            from(rgba(42, 46, 69, 1)),
            to(rgba(42, 46, 69, 0))
        );

        -webkit-mask-image: -webkit-gradient(
            linear,
            80% bottom,
            right bottom,
            from(rgba(42, 46, 69, 1)),
            to(rgba(42, 46, 69, 0))
        );

        & > * {
            margin-left: 16px;

            &:first-child {
                margin-left: 0;
            }
        }

        & > span {
            cursor: default;
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            transition: all 0.2s;
            cursor: pointer;
            opacity: 0.8;

            &.low {
                color: $neon-red !important;
            }

            &.high {
                color: $neon-green !important;
            }

            &:hover,
            &:active {
                opacity: 1;
            }
        }
    }
}
